<template>
    <div>
        <el-card style="margin-top: 8px">
            <div slot="header" class="card-header">
                <span>成为app推广员工</span>
            </div>

            <!-- 成为app推广员工-->
            <el-card style="margin-top: 20px">
                <!--标题-->
                <el-row class="title" :gutter="100" type="flex">
                    <el-col :span="4" class="content1">
                        <el-button type="text" @click="jumpToStaff" class="font1" :disabled="!hasOpenStaffPrivilege">
                            成为朗勤心选app推广员工
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font2">
                        <span>
                            <el-tooltip
                                class="font2"
                                style="text-decoration: underline"
                                :content="roleNamesForQueryStaffRole"
                                placement="top"
                            >
                                <span>此类角色</span>
                            </el-tooltip>
                            可为员工绑定角色
                        </span>
                    </el-col>
                </el-row>
                <!--步骤-->
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-link
                            href="https://8haodi-systemc.oss-cn-beijing.aliyuncs.com/release/“朗勤心选”员工使用及推广说明指导.docx"
                            class="font2"
                            >①app推广员工操作模板</el-link
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span> 点击“app推广员工操作模板”下载文档模板-打开后按文档指南指导完成员工注册 </span>
                    </el-col>
                </el-row>
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToStaff"
                            :disabled="!hasQueryStaffRolePrivilege"
                        >
                            ②绑定员工角色
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>在需要分配角色的员工处点击分配角色-勾选角色-确定</span>
                    </el-col>
                </el-row>
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2">③下载app查看分享二维码</el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>
                            继续按文档指南依次下载朗勤心选app - 用与此系统相同的手机号注册app账号 -
                            完成后等待几分钟可查看是否有个人分享二维码(如有疑问请与八号地客服联系)
                        </span>
                    </el-col>
                </el-row>
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2">④如未显示二维码</el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>
                            如已完成所有操作一段时间后仍未有个人分享二维码，可能此门店暂未与朗勤心选合作，请与八号地客服联系
                        </span>
                    </el-col>
                </el-row>
            </el-card>
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';

const staffPrivilegeFlag = 'menu.system.staff';
const openStaffPrivilegeFlag = `${staffPrivilegeFlag}.open`;
const queryStaffRolePrivilegeFlag = 'biz.roleStaff.query';

export default {
    name: 'AppPopularizeStaffGuide',
    data() {
        return {
            rolesForQueryStaffRole: [],
        };
    },
    created() {
        //查询"能够查询员工角色"的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(queryStaffRolePrivilegeFlag).then((rst) => {
            this.rolesForQueryStaffRole = rst;
        });
    },
    computed: {
        roleNamesForQueryStaffRole() {
            return this.rolesForQueryStaffRole.map((e) => e.name).join('，');
        },
    },

    methods: {
        jumpToStaff() {
            Util.nameJump(this, staffPrivilegeFlag);
        },

        hasOpenStaffPrivilege() {
            return this.hasPrivilege(openStaffPrivilegeFlag);
        },
        hasQueryStaffRolePrivilege() {
            return this.hasPrivilege(queryStaffRolePrivilegeFlag);
        },
    },
};
</script>

<style scoped>
.font1 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: bold;
    text-decoration: underline;
    color: #36bf5d;
    line-height: 28px;
}

.font2 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #606266;
    line-height: 20px;
}

.font3 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #9ca1ab;
    line-height: 20px;
}
.title {
    background: #d6ffe2;
    border-radius: 4px 4px 0 0;
}

.content1 {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content2 {
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.el-row {
    height: 70px;
}

.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
    color: #c0c4cc;
    cursor: not-allowed;
}

/deep/ .el-card__body {
    padding-top: 0;
}

.card-header {
    font-size: 20px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #212121;
    line-height: 28px;
}
</style>
